import React, { useState } from 'react';
import Total from "../Total/Total";
import { setRentalEquity } from "../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const RentalEquityComp = ( props ) => {

    const [rental, setRental] = useState(props.rental);

    function startValue() {
        let val = props.rental[0]
        if (props.num === 2 ) {
            val = props.rental[1]
        }

        return val || 0
    }

    const onChange = val => {
        let rentalUpdated = [...rental];
        if (props.num === 1 ) {
            rentalUpdated[0] = val;
        }else {
            rentalUpdated[1] = val;
        }
        setRental(rentalUpdated)
        props.setRentalEquity(rentalUpdated)
    };

    return (
        <React.Fragment>
            <div className='sva-property-container'>
                <h3 >Rental {props.num}</h3>
                < Total
                    type={'properties'}
                    total={startValue()}
                    title={'Total equity'}
                    onChange={onChange}
                />
            </div>
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        rental: state.savingsAndInvestments.properties.rentalProperties
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRentalEquity : val => dispatch(setRentalEquity(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalEquityComp);