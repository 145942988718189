import React, { useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { setRentalEquity, thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import RentalEquityComp from "../../../../components/ContentRow/Step3/Properties/RentalEquityComp";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const RentalEquity = ( props ) => {

    const [rental, setRental] = useState( props.rental.length || 1 );
    const titles = {
        layoutTitle: 'YOUR SAVINGS & INVESTMENTS',
        sectionTitle: 'What is the equity in your rental property(ies)?',
    };

    const title = "Retirement planning";

    const next = retirementCalcLinks.inheritance;

    useEffect(() => {
        props.thirdStepCompleted(false);
    }, [props]);

    const addCottage = () => {
        setRental(rental+1)
    };

    const onClose = () => {
        setRental(rental-1);

        //reset cottage 2 val
        const rentalValues = props.rental;
        rentalValues.splice(1,1);
        setRentalEquity(rentalValues)
    }

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(15/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='rentalEquity'
                sectionTitle={titles.sectionTitle}
            >

                <InnerLayoutStepContent type={'family'}>
                    <RentalEquityComp  num={1}/>
                </InnerLayoutStepContent>

                {
                    rental === 2 &&
                    <InnerLayoutStepContent style={{position: 'relative'}}>

                        <span className='sva-delete-property'>
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={onClose}
                            />
                        </span>
                        <RentalEquityComp num={2} />

                    </InnerLayoutStepContent>
                }

                {
                    rental < 2 &&
                    <div className='sva-add-another'>
                        <span onClick={addCottage}>+</span>
                        Add another rental
                    </div>
                }


            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        rental: state.savingsAndInvestments.properties.rentalProperties
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRentalEquity : val => dispatch(setRentalEquity(val)),
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalEquity);